<template>
  <div class="p-4">
    <b-row>
      <b-col>
        <b-card style="width: 680px; height: 150px" class="m-auto">
          <h5 class="m-0">{{ $t("loginDetails") }}</h5>
          <span class="text-description">{{ $t("loginDetailsDesc") }}</span>
          <b-row no-gutters>
            <b-col cols="6" class="p-0 d-flex flex-row align-items-end">
              <div class="d-flex flex-column mt-3">
                <span class="text-description">{{ $t("emailAddress") }}</span>
                <span>{{ this.authenticatedUser.attributes.email }}</span>
              </div>
              <b-button
                variant="light"
                size="sm"
                class="ml-3"
                v-b-modal.edit-email
              >
                {{ $t("edit") }}
              </b-button>
            </b-col>
            <b-col cols="6" class="p-0 d-flex flex-row align-items-end">
              <div class="d-flex flex-column mt-3">
                <span class="text-description">{{ $t("password") }}</span>
                <span>*********</span>
              </div>
              <b-button
                variant="light"
                size="sm"
                class="ml-3"
                v-b-modal.edit-password-1
              >
                {{ $t("edit") }}
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row no-gutters class="mt-2">
      <b-col no-gutters>
        <b-card style="width: 680px; height: 150px" class="m-auto">
          <h5>{{ $t("language") }}</h5>
          <b-col cols="6" class="p-0 mt-3">
            <span>{{ $t("currentLanguage") }}</span>
          </b-col>
          <b-col cols="6" class="p-0 d-flex flex-row mt-1">
            <b-form-select :options="languages" v-model="selectedLanguage">
            </b-form-select>
            <b-button variant="primary" class="ml-1" @click="onLanguageSet()"
              >{{ $t("apply") }}
            </b-button>
          </b-col>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <div style="width: 680px; height: 150px" class="m-auto">
          <b-button v-b-modal.delete-account variant="link">
            {{ $t("deleteAccountPermanently") }}
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- MODAL EDIT EMAIL -->
    <b-modal centered id="edit-email" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("editEmailAddress") }}</h5>
      </template>
      <template #default>
        <span>{{ $t("enterNewEmail") }}</span>
        <b-input type="text" class="mt-2"></b-input>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click="changeEmail">
          {{ $t("save") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL EDIT PASSWORD -->
    <b-modal centered id="edit-password-1" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("changePassword") }}</h5>
      </template>
      <template #default>
        <span>{{ $t("enterCurrentEmail") }}</span>
        <b-form-group class="mt-2">
          <b-input-group>
            <b-form-input
              v-model.trim.lazy="$v.form.email.$model"
              type="email"
              required
              :state="validateState('email')"
              :disabled="isLoading"
              @blur="$v.form.email.$touch()"
            />
            <b-form-invalid-feedback>{{
              $t("emailErrMsg")
            }}</b-form-invalid-feedback>
          </b-input-group>
        </b-form-group>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click="emailSubmit()">
          {{ $t("next") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal centered id="edit-password-2" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("changePassword") }}</h5>
      </template>
      <template #default>
        <b-form
          class="mt-2"
          autocomplete="off"
          novalidate
          @submit.prevent="onFormSubmit"
        >
          <b-form-group :label="$t('confirmationCode')">
            <b-form-input
              v-model.trim.lazy="form.code"
              type="text"
              required
              maxlength="6"
              :state="validateState('code')"
              :disabled="isLoading"
              @blur="$v.form.code.$touch()"
              autocomplete="off"
            />
            <b-form-invalid-feedback>{{
              $t("codeErrMsg")
            }}</b-form-invalid-feedback>
          </b-form-group>

          <b-form-group
            :label="$t('newPassword')"
            class="mt-3 position-relative"
          >
            <b-form-input
              v-model.trim.lazy="$v.form.password.$model"
              :type="showPassword ? 'text' : 'password'"
              required
              maxlength="12"
              :state="validateState('password')"
              :disabled="isLoading"
              @blur="$v.form.password.$touch()"
              autocomplete="off"
            />
            <b-form-invalid-feedback>{{
              $t("passwordErrMsg")
            }}</b-form-invalid-feedback>
            <b-icon
              :icon="showPassword ? 'eye' : 'eye-slash'"
              @click="showPassword = !showPassword"
              class="input-icon"
              v-if="!$v.form.password.$invalid"
            ></b-icon>
          </b-form-group>
          <b-form-group
            :label="$t('reenterPassword')"
            class="mt-3 position-relative"
          >
            <b-form-input
              v-model.trim.lazy="$v.form.reenterPassword.$model"
              :type="showPassword ? 'text' : 'password'"
              required
              maxlength="12"
              :state="validateState('password')"
              :disabled="isLoading"
              @blur="$v.form.reenterPassword.$touch()"
              autocomplete="off"
            />
            <b-form-invalid-feedback
              :state="$v.form.reenterPassword.sameAsPassword"
              >{{ $t("reenterPasswordErrMsg") }}</b-form-invalid-feedback
            >
            <b-icon
              :icon="showPassword ? 'eye' : 'eye-slash'"
              @click="showPassword = !showPassword"
              class="input-icon"
              v-if="!$v.form.password.$invalid"
            ></b-icon>
          </b-form-group>
        </b-form>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click="onFormSubmit()">
          {{ $t("save") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL DELETE ACCOUNT -->
    <b-modal centered id="delete-account" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("deleteAccountPermanently") }}</h5>
      </template>
      <template #default>
        <span>{{ $t("deleteDesc") }}</span>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" v-b-modal.delete-account-2>
          {{ $t("continue") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal centered id="delete-account-2" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("deleteAccountPermanently") }}</h5>
      </template>
      <template #default>
        <span>{{ $t("deleteReason") }}</span>
        <b-form-group class="p-2 m-0" v-slot="{ ariaDescribedby }">
          <b-form-radio-group
            v-model="selectedOption"
            :options="options"
            :aria-describedby="ariaDescribedby"
            name="plain-stacked"
            plain
            stacked
          ></b-form-radio-group>
          <b-form-textarea v-if="selectedOption === 'fourth'"></b-form-textarea>
        </b-form-group>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" v-b-modal.delete-account-3>
          {{ $t("continue") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal centered id="delete-account-3">
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("deleteAccountPermanently") }}</h5>
      </template>
      <template #default>
        <span>{{ $t("deleteConfirm") }}</span>
        <b-form-group class="mt-3 mb-0" :label="$t('confirmDelText')">
          <b-form-input v-model="confirmDeleteText" type="text"></b-form-input>
        </b-form-group>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click.prevent="deleteAccount">
          {{ $t("continue") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<i18n>
{
  "en": {
    "loginDetails": "Login Details",
    "loginDetailsDesc": "Email and password you have registered to access Survein",
    "email": "Email",
    "emailAddress": "Email Address",
    "emailErrMsg": "Choose a valid email address.",
    "editEmailAddress": "Edit Email Address",
    "enterNewEmail": "Please enter a new email address for this account",
    "password": "Password",
    "confirmationCode": "Confirmation code",
    "codeErrMsg": "Enter the confirmation code.",
    "newPassword": "New password",
    "reenterPassword": "Re-enter password",
    "passwordErrMsg": "Choose a password between 8-12 characters, with at least 1 uppercase, 1 lowercase, 1 number and 1 special character.",
    "reenterPasswordErrMsg": "Password didn't match.",
    "changePassword": "Change Password",
    "enterCurrentEmail": "Please input the current email to change password",
    "language": "Language",
    "currentLanguage": "Current Language",
    "apply": "Apply",
    "deleteAccountPermanently": "Delete account permanently",
    "deleteDesc": "The account will no longer be available and all data in the account will be permanently deleted.",
    "deleteReason": "Tell us why you want to delete Survein account :",
    "deleteConfirm": "You will permanently lose your data and responses you have collected",
    "confirmDelText": "Type DELETE ACCOUNT to confirm",
    "cancel": "Cancel",
    "continue": "Continue",
    "save": "Save",
    "next": "Next",
    "edit": "Edit",
    "verificationNotValid": "Verification code is not valid",
    "verificationCodeSent": "Verification code has been sent to your email",
    "emailNotFound": "Email not found",
    "passwordChanged": "Password is changed",
    "emailChanged": "Email is changed",
    "accountDeleted": "Account is deleted",
    "languageSaved": "Language is saved"
  },
  "id": {
     "loginDetails": "Rincian Masuk",
     "loginDetailsDesc": "Email dan kata sandi yang telah Anda daftarkan",
     "email": "Email",
     "emailAddress": "Alamat Email",
     "emailErrMsg": "Pilih alamat email yang valid.",
     "editEmailAddress": "Ubah Alamat Email",
     "enterNewEmail": "Masukkan alamat email baru untuk akun ini",
     "password": "Kata Sandi",
     "confirmationCode": "Kode konfirmasi",
     "codeErrMsg": "Masukkan kode konfirmasi.",
     "newPassword": "Kata sandi baru",
     "reenterPassword": "Masukkan ulang kata sandi",
     "passwordErrMsg": "Pilih kata sandi antara 8-12 karakter, dengan minimal 1 huruf besar, 1 huruf kecil, 1 angka dan 1 karakter khusus",
     "reenterPasswordErrMsg": "Kata sandi tidak sama.",
     "changePassword": "Ubah Kata Sandi",
     "enterCurrentEmail": "Silakan masukkan email saat ini untuk mengubah kata sandi",
     "language": "Bahasa",
     "currentLanguage": "Bahasa saat ini",
     "apply": "Terapkan",
     "deleteAccountPermanently": "Hapus akun secara permanen",
     "deleteDesc": "Akun tidak lagi tersedia dan semua data di akun akan dihapus secara permanen.",
     "deleteReason": "Beri tahu kami mengapa Anda ingin menghapus akun Survein Anda:",
     "deleteConfirm": "Anda akan secara permanen kehilangan data dan tanggapan yang telah Anda kumpulkan",
     "confirmDelText": "Ketik DELETE ACCOUNT untuk mengonfirmasi",
     "cancel": "Batal",
     "continue": "Lanjut",
     "save": "Simpan",
     "next": "Berikutnya",
     "edit": "Ubah",
     "verificationNotValid": "Kode verifikasi tidak valid",
     "verificationCodeSent": "Kode verifikasi telah dikirim ke email Anda",
     "emailNotFound": "Email tidak ditemukan",
     "passwordChanged": "Kata sandi diubah",
     "emailChanged": "Email diubah",
     "accountDeleted": "Akun dihapus",
     "languageSaved": "Bahasa disimpan"
  }
}
</i18n>

<script>
import {
  required,
  sameAs,
  email,
  minLength,
  maxLength
} from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      options: [
        { text: "I already have a Survein account", value: "first" },
        {
          text: "I’m not getting any value from my subscription",
          value: "second"
        },
        {
          text: "I find out other form tools are easier to use",
          value: "third"
        },
        { text: "Others", value: "fourth" }
      ],
      languages: [
        { value: "en", text: "English" },
        { value: "id", text: "Bahasa Indonesia" }
      ],
      selectedLanguage: null,
      selectedOption: null,
      confirmDeleteText: "",
      form: {
        email: "",
        code: "",
        password: "",
        reenterPassword: ""
      },
      isLoading: false,
      showPassword: false
    };
  },
  computed: {
    ...mapGetters("profile", ["language", "username"]),
    ...mapGetters("auth", ["authenticatedUser"])
  },
  async mounted() {
    await this.getProfile();
    this.selectedLanguage = this.language;
    if (this.selectedLanguage === null) {
      this.selectedLanguage = "en";
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      code: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6)
      },
      password: {
        required,
        password: v =>
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W]).{8,12}$/.test(v)
      },
      reenterPassword: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  },
  methods: {
    ...mapActions("profile", ["setLanguage", "getProfile"]),
    ...mapActions("auth", ["passwordReset", "confirmPasswordReset"]),

    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty && $error ? false : null;
    },

    async emailSubmit() {
      if (this.form.email === this.authenticatedUser.attributes.email) {
        this.$bvModal.show("edit-password-2");
        this.isLoading = true;
        await this.passwordReset({
          username: this.form.email
        });
        this.$toast.success(this.$t("verificationCodeSent"));
        this.isLoading = false;
      } else {
        this.$toast.error(this.$t("emailNotFound"));
      }
    },

    async onFormSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      this.isLoading = true;
      try {
        await this.confirmPasswordReset({
          username: this.form.email,
          code: this.form.code,
          password: this.form.password,
          vm: this
        });
        this.$toast.success(this.$t("passwordChanged"));
        this.$bvModal.hide("edit-password-2");
      } catch (err) {
        this.$toast.error(this.$t("verificationNotValid"));
      }
    },

    async onLanguageSet() {
      // set this.$root.$i18n instead of this.$i18n
      await this.setLanguage({
        value: this.selectedLanguage,
        vm: this
      });
      this.$root.$i18n.locale = this.selectedLanguage;
    },

    deleteAccount() {
      if (this.confirmDeleteText == "DELETE ACCOUNT") {
        this.$bvModal.hide("delete-account-3");
        this.confirmDeleteText = "";
        this.selected = null;
        this.$toast.success(this.$t("accountDeleted"));
      }
    },
    changeEmail() {
      this.$bvModal.hide("edit-email");
      this.$toast.success(this.$t("emailChanged"));
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-btn {
  background-color: #f4f5f6;
  width: 40px;
  height: 28px;
  text-align: center;
  border-radius: 4px;
  margin-left: 24px;
  cursor: pointer;
  border: none;
}
</style>
