<template>
  <b-container class="py-4 container-height">
    <b-row>
      <b-col>
        <div style="width: 754px; min-height:246px;" class="m-auto">
          <b-row no-gutters>
            <b-alert
              v-if="
                reminderExpiredMonthBefore ||
                  reminderExpiredWeekBefore ||
                  reminderExpiredToday ||
                  (reminderPlanExpired && selectedTeam.license !== 'FREE')
              "
              show
              dismissible
              :class="
                reminderPlanExpired
                  ? 'alert-custom-danger'
                  : 'alert-custom-primary'
              "
            >
              <span
                v-html="$t('subscription.reminderExpiredMonth', { username })"
                class="text-align-center"
                v-if="reminderExpiredMonthBefore"
              />
              <span
                v-html="$t('subscription.reminderExpiredWeek', { username })"
                class="text-align-center"
                v-if="reminderExpiredWeekBefore"
              />
              <span
                v-html="$t('subscription.reminderExpiredToday', { username })"
                class="text-align-center"
                v-if="reminderExpiredToday"
              />
              <span
                v-html="$t('subscription.reminderPlanExpired', { username })"
                class="text-align-center"
                v-if="reminderPlanExpired"
              />
            </b-alert>
            <b-card style="width:194px; height:246px" class="card-border">
              <span class="title-custom">{{ $t("subscription.plan") }}</span>
              <div class="d-flex flex-column mt-3">
                <span class="text-description-custom">{{
                  $t("subscription.planType")
                }}</span>
                <div class="d-flex flex-row">
                  <span>{{ capitalizeString(selectedTeam.license) }}</span>
                  <span class="ml-1" v-if="selectedTeam.license !== 'FREE'">{{
                    capitalizeString(selectedTeam.licenseDuration)
                  }}</span>
                </div>
                <span
                  v-if="selectedTeam.license !== 'FREE'"
                  class="text-description-custom mt-2"
                  >{{ $t("subscription.planExpired") }}</span
                >
                <span v-if="selectedTeam.license !== 'FREE'">{{
                  validUntil
                }}</span>
                <b-button
                  v-if="renew"
                  @click="openRenewModal()"
                  size="sm"
                  class="mt-2"
                  variant="primary"
                  >{{ $t("subscription.renewPlan") }}</b-button
                >
              </div>
            </b-card>

            <b-card style="width:264px; height:246px" class="card-border ml-3">
              <div>
                <span class="title-custom">{{
                  $t("subscription.featurePlan")
                }}</span>
                <div
                  v-if="selectedTeam.license === 'FREE'"
                  class="d-flex flex-row mt-3"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.freeDesc")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license === 'ESSENTIALS'"
                  class="d-flex flex-row mt-3"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.essentialDesc")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license === 'PROFESSIONAL'"
                  class="d-flex flex-row mt-3"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.professionalDesc")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license === 'PREMIUM'"
                  class="d-flex flex-row mt-3"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.premiumDesc")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license === 'FREE'"
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.freeDesc2")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license === 'ESSENTIALS'"
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.essentialDesc2")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license === 'PROFESSIONAL'"
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.professionalDesc2")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license === 'PREMIUM'"
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.premiumDesc3")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license === 'FREE'"
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.freeDesc3")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license !== 'FREE'"
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.desc4")
                  }}</span>
                </div>

                <div
                  v-if="selectedTeam.license === 'FREE'"
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.freeDesc4")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license !== 'FREE'"
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.desc5")
                  }}</span>
                </div>
                <div
                  v-if="
                    selectedTeam.license === 'FREE' ||
                      selectedTeam.license === 'ESSENTIALS'
                  "
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.freeDesc5")
                  }}</span>
                </div>
                <div
                  v-if="
                    selectedTeam.license !== 'FREE' &&
                      selectedTeam.license !== 'ESSENTIALS'
                  "
                  class="d-flex flex-row"
                >
                  <icon name="dash" />
                  <span class="text-description ml-2">{{
                    $t("subscription.desc8")
                  }}</span>
                </div>
                <div
                  v-if="selectedTeam.license !== 'FREE'"
                  class="d-flex flex-row justify-content-center mt-1"
                >
                  <b-button
                    size="sm"
                    variant="link"
                    @click="openRenewModal(false)"
                    >{{ $t("subscription.seeAllFeat") }}</b-button
                  >
                </div>
              </div>
            </b-card>

            <b-card
              style="width: 264px; max-height:246px"
              class="card-border ml-3"
            >
              <div class="d-flex flex-column">
                <span class="title-custom">{{
                  $t("subscription.currentPlan")
                }}</span>
                <b-progress
                  v-if="selectedTeam.license === 'FREE'"
                  class="mt-3"
                  height="4px"
                  :value="selectedTeam.totalForms"
                  :max="selectedTeam.formLimit"
                  :variant="
                    selectedTeam.totalForms >= selectedTeam.formLimit
                      ? 'danger'
                      : 'primary'
                  "
                ></b-progress>
                <div
                  v-if="selectedTeam.license === 'FREE'"
                  class="d-flex justify-content-between"
                  style="width:216px"
                >
                  <span class="text-description mt-1">{{
                    $t("subscription.forms")
                  }}</span>
                  <span class="text-description mt-1"
                    >{{ selectedTeam.totalForms }}/{{
                      selectedTeam.formLimit
                    }}</span
                  >
                </div>
                <b-progress
                  :class="
                    selectedTeam.license === 'FREE' ? 'custom-margin' : 'mt-3'
                  "
                  height="4px"
                  :value="selectedTeam.totalResponses"
                  :max="selectedTeam.responseLimit"
                  :variant="
                    selectedTeam.totalResponses >= selectedTeam.responseLimit
                      ? 'danger'
                      : 'primary'
                  "
                ></b-progress>
                <div class="d-flex justify-content-between" style="width:216px">
                  <span class="text-description mt-1">{{
                    $t("subscription.responses")
                  }}</span>
                  <span class="text-description mt-1"
                    >{{ selectedTeam.totalResponses }}/{{
                      selectedTeam.responseLimit.toLocaleString("id-ID")
                    }}</span
                  >
                </div>
                <span
                  v-if="selectedTeam.license === 'FREE'"
                  class="text-description custom-margin"
                >
                  {{ $t("subscription.getUnlimitedForms") }}
                </span>
                <span
                  v-if="
                    selectedTeam.license !== 'FREE' &&
                      selectedTeam.license !== 'PREMIUM'
                  "
                  class="text-description mt-3"
                >
                  {{ $t("subscription.powerUpSubs") }}
                </span>
                <span
                  v-if="selectedTeam.license === 'PREMIUM'"
                  class="text-description mt-3"
                >
                  {{ $t("subscription.addResponseLimit") }}
                </span>
                <b-button
                  @click="openUpgradeModal()"
                  variant="primary"
                  size="sm"
                  class="custom-margin"
                  block
                >
                  <span v-if="selectedTeam.license === 'FREE'">{{
                    $t("subscription.seePlans")
                  }}</span>
                  <span
                    v-if="
                      selectedTeam.license !== 'FREE' &&
                        selectedTeam.license !== 'PREMIUM'
                    "
                    >{{ $t("subscription.upgradeNow") }}
                  </span>
                  <span
                    v-if="
                      selectedTeam.license === 'PREMIUM' &&
                        selectedTeam.licenseDuration === 'monthly'
                    "
                    >{{ $t("subscription.upgradeNow") }}
                  </span>
                  <span
                    v-if="
                      selectedTeam.license === 'PREMIUM' &&
                        selectedTeam.licenseDuration === 'yearly'
                    "
                    >{{ $t("subscription.addResponses") }}
                  </span>
                </b-button>
              </div>
            </b-card>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <b-card
          style="width: 754px; min-height: 292px"
          class="m-auto card-border"
        >
          <h5 class="m-0">{{ $t("transactionHistory") }}</h5>
          <b-table
            sticky-header
            borderless
            class="mt-3"
            head-row-variant="primary"
            :fields="fields"
            :items="transactionList"
          >
            <template #head(amount)="data">
              <span>{{ data.label }}</span>
            </template>
            <template v-slot:cell(download)="data">
              <b-icon
                @click="download(data.value)"
                icon="download"
                style="color: #166ED8;"
                class="cursor-pointer ml-3"
              ></b-icon>
            </template>
          </b-table>
          <b-row no-gutters v-if="!transactionList">
            <b-col
              style="height:200px"
              cols="12"
              class="p-0 d-flex flex-row align-items-end"
            >
              <div class="m-auto d-flex flex-column align-items-center">
                <h6>{{ $t("noTransaction") }}</h6>
                <span class="text-description">{{
                  $t("afterTransaction")
                }}</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <SubscriptionModal :modalId="'subscription-renew'" ref="renewModal" />
  </b-container>
</template>

<i18n>
{
  "en": {
    "subscription": {
      "freeDesc": "50 Responses per month",
      "freeDesc2": "1 Logic Jumps each form",
      "freeDesc3": "3 Forms",
      "freeDesc4": "10 Questions each form",
      "freeDesc5": "Respondent email notification",
      "premiumDesc" : "10,000 responses per month",
      "premiumDesc2" : "(up to 1,000 responses per 15,000 IDR available)",
      "premiumDesc3" : "10 Logic Jumps",
      "professionalPlans" : "In Professional plans, you will get:",
      "professionalDesc" : "5,000 responses per month",
      "professionalDesc2" : "5 Logic Jumps",
      "essentialsPlans" : "In Essentials plans, you will get:",
      "essentialDesc" : "1,000 responses per month",
      "essentialDesc2" : "3 Logic Jumps",
      "desc4" : "Unlimited forms",
      "desc5" : "Unlimited questions",
      "desc6" : "Respondent email notifications",
      "desc7" : "Schedule a close date",
      "desc8" : "Removal of Survein Logo",
      "seeAllFeat": "See all features",
      "featurePlan": "Features",
      "currentPlan": "Current usage",
      "responses": "Responses",
      "getUnlimitedForms": "Upgrade your plan for unlimited forms and more fancy features.",
      "powerUpSubs": "Power up your Survein plan for engage more audiences and get better features.",
      "seePlans": "See Plans",
      "plan": "Plan",
      "planType": "Plan Type",
      "planExpired": "Plan expired on",
      "forms": "Forms",
      "upgradeNow": "Upgrade Now",
      "addResponses": "Add on Responses",
      "renewPlan": "Renew Plan",
      "addResponseLimit": "You can add on responses limit that meets with your need.",
      "reminderExpiredMonth": "Hi {username}, your plan will be expired in one month. Renew your plan to extend its validity.",
      "reminderExpiredWeek": "Hi {username}, your plan will be expired in 7 days. Renew your plan to extend its validity.",
      "reminderExpiredToday": "Hi {username}, your plan will be expired today. Renew your plan to extend its validity.",
      "reminderPlanExpired": "Hi {username}, your plan has expired. Renew your plan to keep using Survein."
    },
    "transactionHistory": "Transaction History",
    "details": "Details",
    "amount": "Amount (IDR)",
    "date": "Time & Date",
    "invoice": "Invoice",
    "noTransaction": "No transaction yet",
    "afterTransaction": "After you first transaction you will be able to view it here"
  },
  "id": {
    "subscription": {
      "freeDesc": "50 tanggapan per bulan",
      "freeDesc2": "1 Lompatan Logika per formulir",
      "freeDesc3": "3 Formulir",
      "freeDesc4": "10 Pertanyaan per formulir",
      "freeDesc5": "Pemberitahuan email",
      "premiumDesc" : "10.000 tanggapan per bulan",
      "premiumDesc2" : "(hingga 1.000 tanggapan per 15.000 IDR tersedia)",
      "premiumDesc3" : "10 Lompatan Logika",
      "professionalPlans" : "Dalam paket Profesional, Anda akan mendapatkan:",
      "professionalDesc" : "5.000 tanggapan per bulan",
      "professionalDesc2" : "5 Lompatan Logika",
      "essentialsPlans" : "Dalam paket Essentials, Anda akan mendapatkan:",
      "essentialDesc" : "1,000 tanggapan per bulan",
      "essentialDesc2" : "3 Lompatan Logika",
      "desc4" : "Formulir tidak terbatas",
      "desc5" : "Pertanyaan tak terbatas",
      "desc6" : "Pemberitahuan email responden",
      "desc7" : "Jadwalkan tanggal tutup",
      "desc8" : "Penghapusan Logo Survein",
      "seeAllFeat": "Lihat semua fitur",
      "featurePlan": "Fitur",
      "currentPlan": "Penggunaan saat ini",
      "responses": "Tanggapan",
      "getUnlimitedForms": "Tingkatkan paket, dapatkan formulir tanpa batas dan fitur mewah.",
      "powerUpSubs": "Tingkatkan paket Survein Anda untuk melibatkan lebih banyak audiens dan fitur yang lebih baik.",
      "seePlans": "Lihat Paket",
      "plan": "Paket",
      "planType": "Tipe Paket",
      "planExpired": "Paket kedaluwarsa pada",
      "forms": "Formulir",
      "upgradeNow": "Tingkatkan",
      "addResponses": "Tambahkan Tanggapan",
      "renewPlan": "Perbarui Paket",
      "addResponseLimit": "Anda dapat menambahkan batasan respon yang sesuai dengan kebutuhan Anda.",
      "reminderExpiredMonth": "Hai {username}, paket Anda akan kedaluwarsa dalam satu bulan. Perbarui paket Anda <br> untuk memperpanjang validitasnya.",
      "reminderExpiredWeek": "Hai {username}, paket Anda akan kedaluwarsa dalam 7 hari. Perbarui paket Anda <br> untuk memperpanjang validitasnya.",
      "reminderExpiredToday": "Hai {username}, paket Anda akan kedaluwarsa hari ini. Perbarui paket Anda <br> untuk memperpanjang validitasnya.",
      "reminderPlanExpired": "Hai {username}, paket Anda sudah kedaluwarsa. Perpanjang paket Anda <br> untuk tetap menggunakan Survein."
    },
    "transactionHistory": "Riwayat Transaksi",
    "details": "Detail",
    "amount": "Jumlah (IDR)",
    "date": "Waktu & Tanggal",
    "invoice": "Faktur",
    "noTransaction": "Belum ada transaksi",
    "afterTransaction": "Setelah Anda melakukan transaksi pertama, Anda akan dapat melihatnya di sini"
  }
}
</i18n>

<script>
import { mapGetters, mapActions } from "vuex";
import { capitalize } from "lodash";
import moment from "moment";
import { isToday, differenceInDays } from "date-fns";
import SubscriptionModal from "../common/SubscriptionModal";

export default {
  components: {
    SubscriptionModal
  },
  props: {
    selectedTeam: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      fields: [
        {
          key: "productDetail",
          label: this.$t("details")
        },
        {
          key: "createdAt",
          label: this.$t("date"),
          formatter: value => {
            return moment(value).format("h:mm a, DD MMM YYYY");
          }
        },
        {
          key: "amount",
          label: this.$t("amount"),
          formatter: "convertToRupiah",
          class: "text-right"
        }
      ]
    };
  },
  async mounted() {
    if (this.selectedTeam) {
      await this.getTransactionList({ teamId: this.selectedTeam.pk });
    }
  },
  computed: {
    ...mapGetters("team", ["transactionList"]),
    ...mapGetters("profile", ["username"]),
    ...mapGetters("workspace", ["workspaces"]),

    validUntil() {
      return moment(this.selectedTeam.licenseValidUntil).format("DD MMM YYYY");
    },

    daysBeforeExpired() {
      return differenceInDays(this.selectedTeam.licenseValidUntil, new Date());
    },

    reminderExpiredMonthBefore() {
      let reminder = false;
      if (
        this.daysBeforeExpired === 30 &&
        this.selectedTeam.licenseDuration === "yearly"
      ) {
        reminder = true;
      }
      return reminder;
    },

    reminderExpiredWeekBefore() {
      let reminder = false;
      if (this.daysBeforeExpired === 7) {
        reminder = true;
      }
      return reminder;
    },

    reminderExpiredToday() {
      return isToday(new Date(this.selectedTeam.licenseValidUntil));
    },

    reminderPlanExpired() {
      let reminder = false;
      if (this.daysBeforeExpired < 0) {
        reminder = true;
      }
      return reminder;
    },

    renew() {
      let isRenew = false;
      if (
        this.selectedTeam.licenseDuration === "yearly" &&
        this.daysBeforeExpired <= 30
      ) {
        isRenew = true;
      }
      if (
        this.selectedTeam.licenseDuration === "monthly" &&
        this.daysBeforeExpired <= 7
      ) {
        isRenew = true;
      }
      return isRenew;
    }

    // transactionItem() {
    //   let itemLists = null;

    //   for (const items of this.transactionList) {
    //     itemLists = items.map(item => ({
    //       productDetail: item.productDetail,
    //       createdAt: item.createdAt,
    //       amount: item.amount,
    //     }));
    //   }
    //   return itemLists;
    // },
  },
  methods: {
    ...mapActions("team", ["getTransactionList"]),

    capitalizeString(value) {
      return capitalize(value);
    },

    openUpgradeModal() {
      this.$refs.renewModal.showUpgradeModal();
    },

    openRenewModal() {
      this.$refs.renewModal.showRenewModal();
    },

    convertToRupiah(value) {
      var rupiah = "";
      var angkarev = value
        .toString()
        .split("")
        .reverse()
        .join("");
      for (var i = 0; i < angkarev.length; i++)
        if (i % 3 == 0) rupiah += angkarev.substr(i, 3) + ".";
      return rupiah
        .split("", rupiah.length - 1)
        .reverse()
        .join("");
    }
  },
  watch: {
    selectedTeam: {
      immediate: true,
      handler() {
        this.$forceUpdate();
      }
    }
  }
};
</script>

<style scoped>
.row-custom {
  background: #e8f1fb;
  padding: 8px 16px;
  margin-top: 16px;
}

.custom-border {
  border-left: 1px solid #e6e9ed;
  border-right: 1px solid #e6e9ed;
}

.container-height {
  height: calc(100vh - 47px);
  overflow: auto;
}

.title-custom {
  font-size: 18px;
  font-weight: bold;
}

.custom-margin {
  margin-top: 12px;
}

.alert-custom-primary {
  width: 754px;
  background: #e8f1fb;
  text-align: center;
  border: 0.5px solid #166ed8;
}

.alert-custom-danger {
  width: 754px;
  background: #feecea;
  text-align: center;
  border: 0.5px solid #eb5757;
}

.text-description-custom {
  color: #5e6674;
  font-size: 12px;
}
</style>
