<template>
  <section class="h-100 d-flex flex-column">
    <div class="title">
      <h5 class="m-0">{{ $t("settings") }}</h5>
    </div>
    <b-list-group class="flex-grow-1">
      <b-list-group-item
        :active="selectedSidebar === 'account'"
        button
        @click="activeSidebar('account')"
        class="no-border"
      >
        <span>{{ $t("account") }}</span>
      </b-list-group-item>
      <b-list-group-item
        :active="selectedSidebar === 'subscription'"
        button
        @click="activeSidebar('subscription')"
        class="no-border"
      >
        <span>{{ $t("subscriptions") }}</span>
      </b-list-group-item>
      <b-list-group-item
        :active="selectedSidebar === 'team'"
        button
        @click="activeSidebar('team')"
        class="no-border"
      >
        <span>{{ $t("teamManagement") }}</span>
      </b-list-group-item>
    </b-list-group>
  </section>
</template>

<i18n>
{
  "en": {
    "settings": "Settings",
    "account": "Account",
    "subscriptions": "Subscription",
    "teamManagement": "Team Management"
  },
  "id": {
     "settings": "Pengaturan",
     "account": "Akun",
     "subscriptions": "Langganan",
     "teamManagement": "Manajemen Tim"
  }
}
</i18n>

<script>
export default {
  props: {
    selectedSidebar: {
      type: String
    }
  },
  methods: {
    activeSidebar(event) {
      this.$emit("active-sidebar", event);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

.list-group-item.active {
  background-color: #e6f7ff;
  color: $primary;
}

.title {
  padding: 24px 20px;
}

.no-border {
  border: none !important;
  border-color: unset !important;
}
</style>
