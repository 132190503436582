<template>
  <div class="p-4">
    <b-row no-gutters>
      <b-col>
        <div
          style="width: 680px;"
          class="m-auto d-flex flex-row justify-content-between"
        >
          <div style="min-width: 120px;">
            <v-select
              :options="teams"
              label="name"
              :clearable="false"
              v-model="activeTeam"
              @input="changeTeam(activeTeam)"
            >
            </v-select>
          </div>
          <b-button v-b-modal.create-new-team variant="primary">{{
            $t("createTeam")
          }}</b-button>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3">
      <b-col>
        <b-card style="width: 680px; min-height: 150px" class="m-auto">
          <b-row no-gutters>
            <b-col class="d-flex flex-row justify-content-between">
              <div>
                <h5 class="m-0">{{ $t("overview") }}</h5>
                <span class="text-description">{{ $t("overviewDesc") }}</span>
              </div>
              <b-button
                variant="light"
                size="sm"
                class="ml-3"
                v-b-modal.rename-team
              >
                {{ $t("edit") }}
              </b-button>
            </b-col>
          </b-row>
          <b-row no-gutters>
            <b-col cols="6">
              <div class="d-flex flex-column mt-3">
                <span class="text-description">{{ $t("teamName") }}</span>
                <span>{{ selectedTeam.teamName }}</span>
              </div>
            </b-col>
            <b-col cols="6">
              <div class="d-flex flex-column mt-3">
                <span class="text-description">{{ $t("teamDesc") }}</span>
                <span>{{
                  selectedTeam.teamDescription
                    ? selectedTeam.teamDescription
                    : $t("noDescription")
                }}</span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col>
        <b-card style="width: 680px; min-height: 250px" class="m-auto">
          <b-row no-gutters>
            <b-col class="d-flex flex-row justify-content-between">
              <div>
                <h5 class="m-0">
                  {{
                    $t("members", {
                      memberCount: selectedTeam.members.length,
                      memberLimit: selectedTeam.memberLimit
                    })
                  }}
                </h5>
                <div v-if="selectedTeam.license === 'FREE'">
                  <span class="text-description">{{ $t("memberDesc") }}</span>
                  <b-button
                    @click="openUpgradeModal()"
                    class="p-0 ml-1"
                    variant="link"
                    size="sm"
                    >{{ $t("upgrade") }}</b-button
                  >
                  <span class="ml-1 text-description">{{ $t("addMore") }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row
            class="mb-4 mt-2"
            v-if="selectedTeam.license !== 'FREE' && isOwner"
          >
            <b-col cols="6">
              <span class="text-description">{{ $t("inviteNewMember") }}</span>
              <div class="d-flex flex-row mt-1">
                <b-form-input
                  :placeholder="$t('emailPlaceholder')"
                  type="text"
                  class="mr-2"
                  v-model="email"
                ></b-form-input>
                <b-button @click="inviteMember" variant="primary">{{
                  $t("invite")
                }}</b-button>
              </div>
            </b-col>
          </b-row>
          <b-row no-gutters class="mt-2">
            <b-col>
              <b-table
                ref="table"
                hover
                sticky-header
                :fields="tableFields"
                :items="allMembers"
                @row-hovered="rowHovered"
                @row-unhovered="rowUnhovered"
              >
                <template #cell(username)="data">
                  <div class="members">
                    <b-avatar
                      v-if="data.item.role"
                      variant="primary"
                      size="sm"
                      :text="
                        data.item.username
                          ? data.item.username.slice(0, 2)
                          : 'SV'
                      "
                      class="mr-2"
                    ></b-avatar>
                    <icon
                      style="width: 24px; height: 24px; margin-right: 8px"
                      v-if="data.item.accepted === false"
                      name="no-member"
                    />
                    <span
                      :style="
                        data.item.accepted === false ? 'color: #5E6674' : ''
                      "
                      >{{
                        data.item.username ? data.item.username : "Pending"
                      }}</span
                    >
                  </div>
                </template>
                <template #cell(email)="data">
                  <span
                    :style="
                      data.item.accepted === false ? 'color: #5E6674' : ''
                    "
                    >{{ data.item.email }}</span
                  >
                </template>

                <template #cell(role)="data">
                  <b-dropdown
                    :disabled="data.item.accepted === false"
                    class="custom-dropdown"
                    size="sm"
                    variant="light"
                  >
                    <template #button-content>
                      <span class="mr-4">{{
                        data.item.role === "owner"
                          ? $t("owner")
                          : $t("collaborator")
                      }}</span>
                    </template>
                    <b-dropdown-item
                      :disabled="!isOwner"
                      @click.prevent="openTransferTeamModal(data)"
                      >{{ $t("owner") }}</b-dropdown-item
                    >
                    <b-dropdown-item
                      :disabled="!isOwner"
                      @click.prevent="openTransferTeamModal(data)"
                      >{{ $t("collaborator") }}</b-dropdown-item
                    >
                  </b-dropdown>
                </template>
                <template #head(delete)>
                  <div style="width: 30px;"></div>
                </template>
                <template #cell(delete)="data">
                  <div v-if="isHovered(data.item)">
                    <span
                      v-b-tooltip.hover.left="$t('transferOwnership')"
                      tabindex="0"
                      v-if="data.item.role === 'owner' && isOwner"
                    >
                      <b-button disabled class="p-0" variant="link" size="sm">
                        <b-icon
                          variant="secondary"
                          class="cursor-pointer"
                          icon="dash-circle-fill"
                          >{{ data }}</b-icon
                        >
                      </b-button>
                    </span>
                    <span
                      v-if="
                        data.item.role !== 'owner' &&
                          isOwner &&
                          data.item.type !== 'TEAM_INVITATION'
                      "
                    >
                      <b-button
                        class="p-0"
                        variant="link"
                        size="sm"
                        @click="openRemoveMemberModal(data)"
                      >
                        <b-icon
                          variant="secondary"
                          class="cursor-pointer"
                          icon="dash-circle-fill"
                          >{{ data }}</b-icon
                        >
                      </b-button>
                    </span>
                    <span v-if="!isOwner">
                      <b-button disabled class="p-0" variant="link" size="sm">
                        <b-icon
                          variant="secondary"
                          class="cursor-pointer"
                          icon="dash-circle-fill"
                          >{{ data }}</b-icon
                        >
                      </b-button>
                    </span>
                    <span v-if="isOwner && data.item.accepted === false">
                      <b-button
                        class="p-0"
                        variant="link"
                        size="sm"
                        @click="openRemoveInvitationModal(data)"
                      >
                        <b-icon
                          variant="secondary"
                          class="cursor-pointer"
                          icon="dash-circle-fill"
                          >{{ data }}</b-icon
                        >
                      </b-button>
                    </span>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="mt-2" v-if="invitations.length">
      <b-col>
        <b-card style="width: 680px; min-height: 200px" class="m-auto">
          <b-row no-gutters>
            <b-col class="d-flex flex-row justify-content-between">
              <div>
                <h5 class="m-0">{{ $t("invitations") }}</h5>
              </div>
            </b-col>
          </b-row>
          <b-row no-gutters class="mt-2">
            <b-col>
              <b-table
                hover
                sticky-header
                :fields="invitationTableFields"
                :items="invitations"
              >
                <template #cell(teamname)="data">
                  <span>{{ data.item.teamName }}</span>
                </template>
                <template #cell(action)="data">
                  <b-button
                    @click="rejectInvitation(data.item.pk)"
                    size="sm"
                    variant="danger"
                    class="mr-2"
                    >{{ $t("reject") }}</b-button
                  >
                  <b-button
                    @click="acceptInvitation(data.item.pk)"
                    size="sm"
                    variant="primary"
                    >{{ $t("accept") }}</b-button
                  >
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <!-- MODAL CREATE NEW TEAM -->
    <b-modal centered id="create-new-team" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("createNewTeam") }}</h5>
      </template>
      <template #default>
        <div>
          <span>{{ $t("teamName") }}</span>
          <b-input
            :placeholder="$t('teamNamePlaceholder')"
            type="text"
            class="mt-2"
          ></b-input>
        </div>
        <div class="mt-2">
          <span>{{ $t("teamDesc") }}</span>
          <b-form-textarea
            :placeholder="$t('teamDescPlaceholder')"
            type="text"
            class="mt-2"
          ></b-form-textarea>
        </div>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary">
          {{ $t("create") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL RENAME TEAM -->
    <b-modal centered id="rename-team" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("renameTeam") }}</h5>
      </template>
      <template #default>
        <b-form>
          <b-form-group :label="$t('teamName')">
            <b-form-input
              :placeholder="$t('teamNamePlaceholder')"
              :state="$v.editTeam.name.required ? null : false"
              v-model="editTeam.name"
              type="text"
            ></b-form-input>
            <div class="error" v-if="!$v.editTeam.name.required">
              {{ $t("teamNameError") }}
            </div>
          </b-form-group>
          <b-form-group :label="$t('teamDesc')">
            <b-form-textarea
              :placeholder="$t('teamDescPlaceholder')"
              v-model="editTeam.description"
              :state="$v.editTeam.description.maxLength ? null : false"
              type="text"
            ></b-form-textarea>
            <div class="error" v-if="!$v.editTeam.description.maxLength">
              {{ $t("teamDescError") }}
            </div>
          </b-form-group>
        </b-form>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click="teamUpdate">
          {{ $t("save") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL DELETE TEAM -->
    <b-modal centered id="delete-team" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("deleteTeam") }}</h5>
      </template>
      <template #default>
        <span>{{ $t("deleteTeamDesc") }}</span>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" v-b-modal.delete-team-2>
          {{ $t("continue") }}
        </b-button>
      </template>
    </b-modal>

    <b-modal centered id="delete-team-2" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("deleteTeam") }}</h5>
      </template>
      <template #default>
        <b-form-group class="mb-0" :label="$t('confirmDelTeam')">
          <b-form-input v-model="confirmDeleteTeam" type="text"></b-form-input>
        </b-form-group>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="danger" @click="deleteTeam">
          {{ $t("deleteTeam") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL TRANSFER TEAM OWNERSHIP-->
    <b-modal centered id="transfer-team-ownership" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("transferTeamOwner") }}</h5>
      </template>
      <template #default>
        <span>{{
          $t("transferTeamOwnerDesc", {
            memberName: selectedMember.name,
            teamName: selectedTeam.teamName
          })
        }}</span>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click="transferTeamOwner">
          {{ $t("transferOwner") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL REMOVE ME FROM TEAM-->
    <b-modal centered id="remove-me" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">{{ $t("removeMeFromTeam") }}</h5>
      </template>
      <template #default>
        <span>{{ $t("removeMeFromTeamDesc") }}</span>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary">
          {{ $t("removeMyself") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL DELETE MEMBER -->
    <b-modal centered id="remove-member" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">
          {{ $t("removeMember") }}
        </h5>
      </template>
      <template #default>
        <span>{{
          $t("removeMemberDesc", {
            memberName: selectedMember.name,
            teamName: selectedTeam.teamName
          })
        }}</span>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click="deleteMember">
          {{ $t("remove") }}
        </b-button>
      </template>
    </b-modal>

    <!-- MODAL DELETE INVITATION -->
    <b-modal centered id="remove-invitation" no-stacking>
      <template #modal-title>
        <h5 class="mb-0 col-12">
          {{ $t("deleteInvitation") }}
        </h5>
      </template>
      <template #default>
        <span>{{
          $t("deleteInvitationDesc", {
            email: selectedMember.email
          })
        }}</span>
      </template>
      <template #modal-footer="{ cancel }">
        <b-button variant="light" @click="cancel()">
          {{ $t("cancel") }}
        </b-button>
        <b-button variant="primary" @click="deleteInvitation">
          {{ $t("remove") }}
        </b-button>
      </template>
    </b-modal>

    <SubscriptionModal :modalId="'subscription-renew'" ref="upgradeModal" />
  </div>
</template>

<i18n>
{
  "en": {
    "overview": "Overview",
    "overviewDesc": "Brief explanation about the team.",
    "deleteTeam": "Delete Team",
    "teamName": "Team name",
    "teamDesc": "Team description",
    "edit": "Edit",
    "members": "Members {memberCount}/{memberLimit}",
    "memberDesc": "Free plan only available for 1 member.",
    "username": "Username",
    "assignedEmail": "Assigned email",
    "permission": "Permission",
    "createTeam": "Create team",
    "inviteNewMember": "Invite new members by adding their emails",
    "cancel": "Cancel",
    "create": "Create",
    "save": "Save",
    "continue": "Continue",
    "invite": "Invite",
    "add": "Add",
    "createNewTeam": "Create new team",
    "renameTeam": "Rename Team Overview",
    "deleteTeamDesc": "The team will no longer be available and all data in the team will be permanently deleted.",
    "confirmDelTeam": "Type DELETE TEAM to confirm",
    "emailPlaceholder": "Eg: john.doe@survein.com",
    "teamNamePlaceholder": "Eg: Marketing, Product, or Operation",
    "teamDescPlaceholder": "Brief description about the team",
    "searchPlaceholder": "Search",
    "noDescription": "No description yet",
    "switchTeam": "Switch Team To",
    "teamNameError": "Team name required",
    "teamDescError": "Maximum 80 Character",
    "teamOverviewChanged": "Team overview is changed",
    "transferTeamOwner": "Transfer Team Ownership",
    "transferTeamOwnerDesc": "You will no longer be able to manage the team once you transfer the team ownership to {memberName} for the {teamName} team.",
    "transferOwner": "Transfer ownership",
    "removeMeFromTeam": "Remove Me from the Team",
    "removeMeFromTeamDesc": "You will no longer be able to contribute in this team once you remove yourself. Are you sure want to do it?",
    "removeMyself": "Remove myself",
    "teamName": "Team name",
    "action": "Action",
    "invitations": "Invitations",
    "reject": "Reject",
    "accept": "Accept",
    "upgrade": "Upgrade plan",
    "addMore": "to add more",
    "owner": "Owner",
    "collaborator": "Collaborator",
    "removeMember": "Delete member",
    "removeMemberDesc": "Are you sure want to delete {memberName} from {teamName} team?",
    "remove": "Remove",
    "transferOwnership": "Please transfer the team ownership before removing yourself",
    "errorRemoveMember": "An error occurred while removing the member",
    "errorGetInvitation": "An error occurred while getting the invitation",
    "errorAcceptInvitation": "An error occurred while accepting the invitation",
    "errorRejectInvitation": "An error occurred while rejecting the invitation",
    "errorTransferOwner": "An error occurred while transferring the owner",
    "removeMemberSuccess": "Member removed",
    "transferTeamOwnerSuccess": "Team owner has been updated",
    "successAcceptInvitation": "Invitation accepted",
    "invitationSent": "Invitation sent",
    "invitationError": "An error occurred while inviting the member",
    "deleteInvitation": "Delete invitation",
    "deleteInvitationDesc": "Are you sure want to remove invitation to {email}?",
    "invitationRemoved": "Invitation removed",
    "errorRemoveInvitation": "An error occurred while removing the invitation"
  },
  "id": {
    "overview": "Ringkasan",
    "overviewDesc": "Penjelasan singkat tentang tim.",
    "deleteTeam": "Hapus tim",
    "teamName": "Nama tim",
    "teamDesc": "Deskripsi tim",
    "edit": "Ubah",
    "members": "Anggota {memberCount}/{memberLimit}",
    "memberDesc": "Free plan only available for 1 member.",
    "username": "Nama pengguna",
    "assignedEmail": "Email terdaftar",
    "permission": "Izin",
    "createTeam": "Buat tim",
    "inviteNewMember": "Undang anggota baru dengan menambahkan email mereka",
    "cancel": "Batal",
    "create": "Buat",
    "save": "Simpan",
    "continue": "Lanjutkan",
    "invite": "Undang",
    "add": "Tambah",
    "createNewTeam": "Buat tim baru",
    "renameTeam": "Ganti Nama Tim",
    "deleteTeamDesc": "Tim tidak akan tersedia lagi dan semua data dalam tim akan dihapus secara permanen.",
    "confirmDelTeam": "Ketik DELETE TEAM untuk mengonfirmasi",
    "emailPlaceholder": "Cth: john.doe@survein.com",
    "teamNamePlaceholder": "Cth: Marketing, Product, atau Operation",
    "teamDescPlaceholder": "Deskripsi singkat tentang tim",
    "searchPlaceholder": "Cari",
    "noDescription": "Belum ada deskripsi",
    "switchTeam": "Ganti Tim Ke",
    "teamNameError": "Nama tim diperlukan",
    "teamDescError": "Maksimal 80 Karakter",
    "teamOverviewChanged": "Keterangan tim diubah",
    "transferTeamOwner": "Pindahkan Kepemilikan Tim",
    "transferTeamOwnerDesc": "Anda tidak akan dapat lagi mengelola tim setelah Anda mengalihkan kepemilikan tim ke {memberName} untuk tim {teamName}.",
    "transferOwner": "Transfer ownership",
    "removeMeFromTeam": "Keluarkan Saya dari Tim",
    "removeMeFromTeamDesc": "Anda tidak dapat lagi berkontribusi dalam tim ini setelah Anda menghapus diri sendiri. Apakah Anda yakin ingin melakukannya?",
    "removeMyself": "Hapus diriku sendiri",
    "teamName": "Nama tim",
    "action": "Action",
    "invitations": "Undangan",
    "reject": "Tolak",
    "accept": "Terima",
    "upgrade": "Tingkatkan paket",
    "addMore": "untuk menambah",
    "owner": "Pemilik",
    "collaborator": "Kolaborator",
    "removeMember": "Hapus anggota",
    "removeMemberDesc": "Apakah kamu yakin untuk menghapus {memberName} dari {teamName} tim?",
    "remove": "Hapus",
    "transferOwnership": "Harap transfer kepemilikan tim sebelum menghapus diri Anda sendiri",
    "errorRemoveMember": "Terjadi kesalahan saat menghapus anggota",
    "errorGetInvitation": "Terjadi kesalahan saat menerima undangan",
    "errorAcceptInvitation": "Terjadi kesalahan saat menerima undangan",
    "errorRejectInvitation": "Terjadi kesalahan saat menolak undangan",
    "errorTransferOwner": "Terjadi kesalahan saat mentransfer pemilik",
    "removeMemberSuccess": "Berhasil menghapus anggota",
    "transferTeamOwnerSuccess": "Kepemilikan tim diperbarui",
    "successAcceptInvitation": "Undangan diterima",
    "invitationSent": "Undangan dikirim",
    "invitationError": "Terjadi kesalahan saat mengundang anggota",
    "deleteInvitation": "Hapus undangan",
    "deleteInvitationDesc": "Anda yakin ingin menghapus undangan ke {email}?",
    "invitationRemoved": "Undangan dihapus",
    "errorRemoveInvitation": "Terjadi kesalahan saat menghapus undangan"
  }
}
</i18n>

<script>
import { mapActions, mapGetters } from "vuex";
import { maxLength, required } from "vuelidate/lib/validators";
import { API } from "aws-amplify";
import { API_NAME } from "@/api/APIConfig";
import SubscriptionModal from "../common/SubscriptionModal";

export default {
  components: {
    SubscriptionModal
  },
  validations: {
    editTeam: {
      name: {
        required
      },
      description: {
        maxLength: maxLength(80)
      }
    }
  },
  data() {
    return {
      isLoading: false,
      activeTeam: null,
      confirmDeleteTeam: "",
      email: "",
      selectedMember: {
        name: "",
        email: "",
        invitationId: ""
      },
      editTeam: {
        name: "",
        description: ""
      },
      tableFields: [
        {
          key: "username",
          label: this.$t("username")
        },
        {
          key: "email",
          label: this.$t("assignedEmail")
        },
        {
          key: "role",
          label: this.$t("permission")
        },
        "delete"
      ],
      invitationTableFields: [
        {
          key: "teamname",
          label: this.$t("teamName")
        },
        {
          key: "action",
          label: this.$t("action")
        }
      ],
      fieldsNewTeam: [
        {
          key: "username",
          label: this.$t("username")
        },
        {
          key: "permission",
          label: this.$t("permission")
        },
        "delete"
      ],
      invitations: [],
      pendingInvitations: [],
      hoveredRow: null
    };
  },
  props: {
    teams: {
      type: Array,
      required: true
    }
  },
  methods: {
    ...mapActions("team", ["updateTeam", "getTeam", "inviteTeam"]),
    ...mapActions("profile", ["getProfile"]),

    async getInvitations() {
      try {
        const invitations = await API.get(
          API_NAME,
          `/teams/list/${this.authenticatedUser.attributes.email}/get-invitations`
        );
        this.invitations = invitations;
      } catch (err) {
        this.$toast.warning(this.$t("errorGetInvitation"));
      }
    },

    async getTeamInvitations() {
      try {
        const invitations = await API.get(
          API_NAME,
          `/teams/${this.selectedTeam.pk}/list-invitations`
        );
        this.pendingInvitations = invitations;
      } catch (err) {
        this.$toast.warning(this.$t("errorGetInvitation"));
      }
    },

    async acceptInvitation(invitationId) {
      try {
        await API.post(API_NAME, `/invite/${invitationId}/accept`);
        await this.getInvitations();
        await this.getProfile();
        this.$toast.success(this.$t("successAcceptInvitation"));
      } catch (err) {
        this.$toast.warning(this.$t("errorAcceptInvitation"));
      }
    },

    async rejectInvitation(invitationId) {
      try {
        await API.post(API_NAME, `/invite/${invitationId}/reject`);
        await this.getInvitations();
      } catch (err) {
        this.$toast.warning(this.$t("errorRejectInvitation"));
      }
    },

    deleteTeam() {
      if (this.confirmDeleteTeam == "DELETE TEAM") {
        this.$bvModal.hide("delete-team-2");
        this.confirmDeleteTeam = "";
        this.$toast.success(this.$t("teamDeleted"));
      }
    },

    async teamUpdate() {
      this.$v.editTeam.$touch();
      if (this.$v.editTeam.$anyError) {
        return;
      }

      await this.updateTeam({
        teamId: this.selectedTeam.pk,
        name: this.editTeam.name,
        description: this.editTeam.description
      });
      this.getTeam({ teamId: this.selectedTeam.pk });
      this.$toast.success(this.$t("teamOverviewChanged"));
      this.$bvModal.hide("rename-team");
    },

    changeTeam(team) {
      this.getTeam({ teamId: team.id });
    },

    openTransferTeamModal(event) {
      this.selectedMember.name = event.item.username;
      this.selectedMember.email = event.item.email;
      this.$bvModal.show("transfer-team-ownership");
    },

    async inviteMember() {
      try {
        await this.inviteTeam({
          teamId: this.selectedTeam.pk,
          email: this.email
        });
        await this.getTeamInvitations();
        this.$toast.success(this.$t("invitationSent"));
      } catch (error) {
        this.$toast.warning(this.$t("invitationError"));
      }
    },

    openUpgradeModal() {
      this.$refs.upgradeModal.showUpgradeModal();
    },

    openRemoveMemberModal(event) {
      this.selectedMember.name = event.item.username;
      this.selectedMember.email = event.item.email;
      this.$bvModal.show("remove-member");
    },

    openRemoveInvitationModal(event) {
      this.selectedMember.name = event.item.username;
      this.selectedMember.email = event.item.email;
      this.selectedMember.invitationId = event.item.pk;
      this.$bvModal.show("remove-invitation");
    },

    async deleteMember() {
      try {
        const email = this.selectedMember.email;
        const body = { email };

        await API.post(
          API_NAME,
          `/teams/${this.selectedTeam.pk}/remove-member`,
          { body }
        );

        await this.getTeam({ teamId: this.selectedTeam.pk });
        this.$toast.success(this.$t("removeMemberSuccess"));

        this.$bvModal.hide("remove-member");
        this.selectedMember.name = "";
        this.selectedMember.email = "";
      } catch (err) {
        this.$toast.warning(this.$t("errorRemoveMember"));
      }
    },

    async deleteInvitation() {
      try {
        const email = this.selectedMember.email;
        const invitationId = this.selectedMember.invitationId;
        const body = { email };

        await API.post(
          API_NAME,
          `/teams/${this.selectedTeam.pk}/invitations/${invitationId}/delete`,
          { body }
        );

        await this.getTeamInvitations();
        this.$toast.success(this.$t("invitationDeleted"));

        this.$bvModal.hide("remove-invitation");
        this.selectedMember.name = "";
        this.selectedMember.email = "";
        this.selectedTeam.invitationId = "";
      } catch (err) {
        this.$toast.warning(this.$t("errorRemoveInvitation"));
      }
    },

    async transferTeamOwner() {
      try {
        const email = this.selectedMember.email;
        const body = { email };

        await API.post(
          API_NAME,
          `/teams/${this.selectedTeam.pk}/change-owner`,
          { body }
        );
        this.getTeam({ teamId: this.selectedTeam.pk });

        this.$bvModal.hide("transfer-team-ownership");
        this.$toast.success(this.$t("transferTeamOwnerSuccess"));
        this.selectedMember.name = "";
        this.selectedMember.email = "";
      } catch (err) {
        this.$toast.warning(this.$t("errorTransferOwner"));
      }
    },

    rowHovered(item) {
      this.hoveredRow = item;
      this.$refs.table.refresh();
    },

    rowUnhovered() {
      this.hoveredRow = null;
    },

    isHovered(item) {
      return item == this.hoveredRow;
    }
  },
  computed: {
    ...mapGetters("team", ["selectedTeam"]),
    ...mapGetters("auth", ["authenticatedUser"]),

    isOwner() {
      if (this.selectedTeam) {
        const owner = this.selectedTeam.members.find(
          member => member.role === "owner"
        );

        return (
          !!owner && owner.email === this.authenticatedUser.attributes.email
        );
      }
      return false;
    },

    allMembers() {
      return [...this.selectedTeam.members, ...this.pendingInvitations];
    }
  },
  async mounted() {
    this.activeTeam = this.teams.find(team => team.id === this.selectedTeam.pk);
    this.editTeam.name = this.selectedTeam.teamName;
    this.editTeam.description = this.selectedTeam.teamDescription;
    await this.getInvitations();
    await this.getTeamInvitations();
  },
  watch: {
    selectedTeam: {
      immediate: true,
      deep: true,
      handler() {
        this.$forceUpdate();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.custom-btn {
  background-color: #f4f5f6;
  width: 40px;
  height: 28px;
  text-align: center;
  border-radius: 4px;
  margin-left: 24px;
  cursor: pointer;
  border: none;
}

.custom-dropdown {
  height: 25px;
  display: flex;
  align-items: center;
}

.custom-select {
  height: 25px;
  padding: 0px 8px;
}

.error {
  color: #f7195a;
  font-size: 14px;
  margin-top: 4px;
}

.members {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
