<template>
  <SidebarMainLayout>
    <template #sidebar>
      <SettingSidebar
        @active-sidebar="selectActiveSidebar"
        :selected-sidebar="activeSidebar"
      />
    </template>
    <SettingAccount v-if="activeSidebar === 'account'" />
    <SettingSubscription
      :selectedTeam="selectedTeam"
      v-if="activeSidebar === 'subscription'"
    />
    <SettingTeam :teams="teams" v-if="activeSidebar === 'team'" />
  </SidebarMainLayout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SidebarMainLayout from "@/layouts/SidebarMainLayout";
import SettingSidebar from "@/components/Account/SettingSidebar";
import SettingAccount from "@/components/Account/SettingAccount";
import SettingSubscription from "@/components/Account/SettingSubscription";
import SettingTeam from "@/components/Account/SettingTeam";

export default {
  components: {
    SidebarMainLayout,
    SettingSidebar,
    SettingAccount,
    SettingSubscription,
    SettingTeam
  },
  data() {
    return {
      activeSidebar: "account"
    };
  },
  computed: {
    ...mapGetters("profile", ["teams"]),
    ...mapGetters("team", ["selectedTeam"])
  },
  methods: {
    ...mapActions("team", ["getTeam", "setSelectedTeam"]),
    selectActiveSidebar(event) {
      this.activeSidebar = event;
    },
    async selectActiveTeam(team) {
      await this.getTeam({ teamId: team.id });
    }
  }
};
</script>
